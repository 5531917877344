// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* html, body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: salmon;
    font-family: "Quicksand", sans-serif;
} */

#container_anim {
    position: relative;
    width: 100%;
    height: 70%;
}

#key {
    position: absolute;
    top: 77%;
    left: 0%;
}

#text {
    font-size: 4rem;
    position: absolute;
    top: 55%;
    width: 100%;
    text-align: center;
}

#credit {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

a {
    color: rgb(115, 102, 102);
}
`, "",{"version":3,"sources":["webpack://./src/components/unauthorized/Unauthorized.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG;;AAEH;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,QAAQ;AACZ;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* html, body {\r\n    margin: 0;\r\n    padding: 0;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: salmon;\r\n    font-family: \"Quicksand\", sans-serif;\r\n} */\r\n\r\n#container_anim {\r\n    position: relative;\r\n    width: 100%;\r\n    height: 70%;\r\n}\r\n\r\n#key {\r\n    position: absolute;\r\n    top: 77%;\r\n    left: 0%;\r\n}\r\n\r\n#text {\r\n    font-size: 4rem;\r\n    position: absolute;\r\n    top: 55%;\r\n    width: 100%;\r\n    text-align: center;\r\n}\r\n\r\n#credit {\r\n    position: absolute;\r\n    bottom: 0;\r\n    width: 100%;\r\n    text-align: center;\r\n}\r\n\r\na {\r\n    color: rgb(115, 102, 102);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
